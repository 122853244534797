import * as THREE from 'three';
import { PMREMGenerator }  from 'three/examples/jsm/pmrem/PMREMGenerator.js';

import './style.css';
import './shaderChunks.js';


import {Curves} from 'three/examples/jsm/curves/CurveExtras.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js';

import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {CONTROL, Preloader, eventOpts, findNewPoint, distanceVector} from './nick-utils.js';
import {UI} from './ui.js';
import {TexMap} from './TexMapper';


import {ModelLoad} from './model_load';


import fshader from './shaders/fshader.glsl';
import vshader from './shaders/vshader.glsl';
import fshader_wood from './shaders/fshader_wood.glsl';
import vshader_wood from './shaders/vshader_wood.glsl';
import fshader_fire from './shaders/fshader_fireX.glsl';
import vshader_fire from './shaders/vshader_fire.glsl';

let Office;

document.addEventListener("DOMContentLoaded", function(){
 
  const room = new Room();
  window.room = room;//For debugging only
 
 

});



/**
 * Class methods for creating three scene
 */
class Room{
  constructor(){
    this.camera;
    this.cameraPersp;
    this.cameraOrtho;
    this.clock = new THREE.Clock();
    this.scene;
    this.fanOn= false;
    this.fanblades=null;
    this.daylights = new THREE.Group();
    this.daylights.name="lights";
    this.luxolight=null;
    this.desklight=null;
    this.floorlight=null;
    this.posepos= new THREE.Vector3();
    this.tube;
    this.ui;
    this.rframe = 0;
    this.reset =[ new THREE.Vector3(),new THREE.Vector3()] ;
    this.cubesPath = '../src/texture/cube';
    this.cubeTextureLoader;
    this.SkyhookPath ='Canary';
    this.texs=["wall","ceiling","floor/parquet0","wood/bark","wood","fine_wood","steel","fabric/brun","fabric/red"];
    this.texsPath='../src/texture';
    this.models=["baseboard","office_space_shine","doors_windows"];
    this.baller;
    this.U=null;
    this.cameras = [];
    this.mixers=[];
    this.proxys= new THREE.Group({name: "proxys"});
    this.proxys.name = "proxy";
    this.modelsPath = '../src/model';
    this.clock = new THREE.Clock();
    this.control = CONTROL;
    this.dragObject = null;
    this.spaces=["office_space_shine"] ;
    this.loaded=[];
    this.waitfor=()=>{};
    this.fullyloaded=false;

    this.carp = new TexMap({
              texPath:"../src/texture", dir:"fabric/red", xrep:4, yrep:4, type:"standard",
              xoff:0, yoff:0, aoi:1, roughi:1.0, metali:0.0, roti: 0, hook:"s", 
    }).output();
   
    this.bark = new TexMap({
              texPath:"../src/texture", dir:"wood/bark", xrep:1, yrep:1, type:"standard",
              xoff:0, yoff:0, aoi:1, roughi:1.0, metali:0.1, roti: 0, hook:"s"
    }).output();


    this.F = {
      u_tex: { value: new THREE.TextureLoader().load("../src/texture/flame.png") },
      u_time: { value: 0.0 },
      u_mouse: { value:{ x:0.0, y:0.0 }},
      u_resolution: { value:{ x:0, y:0 }},
      u_color_a: { value: new THREE.Color(0xff0000) },
      u_color_b: { value: new THREE.Color(0xffff00) },
    }
   
    this.W ={
     u_time : { value: 0.0 },
     u_resolution: { value: new THREE.Vector2() },
     u_LightColor: { value: new THREE.Color(0x000000) },
     u_DarkColor: { value: new THREE.Color(0x73583c) },
     u_Frequency: { value: 4.0 },
     u_NoiseScale: { value: 10.0 },
     u_RingScale: { value: 12.6 },
     u_Contrast: { value: 4.0 },
    }

    this.U = {
      u_color: { value: new THREE.Color(0xffcc00) },
      u_time: { value: 0.0 },
      u_dtime: { value: 0.0 },
      u_mouse: { value:{ x:0.0, y:0.0, z:0.0 }},
      u_resolution: { value:{ x:0.0, y:0.0 }},
      u_randy: { value: Math.random()}
    }

    this.U.u_resolution.value.x = window.innerWidth;
    this.U.u_resolution.value.y = window.innerHeight;
  
    this.tvNoise = new THREE.ShaderMaterial( {
      uniforms: this.U,
      vertexShader: vshader,
      fragmentShader: fshader,
      wireframe: false,
      side: THREE.DoubleSide,
    } );

    this.wood = new THREE.ShaderMaterial( {
      uniforms: this.W,
      vertexShader: vshader_wood,
      fragmentShader: fshader_wood,
      wireframe: false,
      side: THREE.DoubleSide,
    } );

    this.fire = new THREE.ShaderMaterial( {
      uniforms: this.F,
      vertexShader: vshader_fire,
      fragmentShader: fshader_fire,
      wireframe: false,
      side: THREE.DoubleSide,
    } );

    this.clock = new THREE.Clock();

    ///container element for preloader, needs to remove after load
    this.container = document.createElement( 'div' );
    this.container.style.height = '100%';
    this.container.setAttribute("class", "pie-wrap");
    document.body.appendChild( this.container );

    this.mloader = new ModelLoad({
      room: this,
    });

    const room = this;
    const options = {
      assets:[
      ],
      oncomplete: function(){
        Office =`${room.modelsPath}/${room.spaces[0]}.glb`;
        room.init_three();
        room.add_efx();
        room.scene.add(room.proxys);
        room.mloader.load_GLTF(Office, 'Office' ) ;
        room.setMsg("frame loaded");
        document.body.removeChild( room.container );
       
        room.animate();
      
        room.ui = new UI(room);
///////////////chained loader////////////////////
        room.getBaseboard();
///////////////////////////////////////////

      },
      container: this.container,
   }
   
    this.models.forEach( function (vx){options.assets.push(`${room.modelsPath}/${vx}.glb`)})
    this.texs.forEach( function (vx){options.assets.push(`${room.texsPath}/${vx}/ao.png`)})
    this.texs.forEach( function (vx){options.assets.push(`${room.texsPath}/${vx}/norm.png`)})
    this.texs.forEach( function (vx){options.assets.push(`${room.texsPath}/${vx}/rough.png`)})
    this.texs.forEach( function (vx){options.assets.push(`${room.texsPath}/${vx}/diffuse.png`)})

    options.self = this;
    const preloader = new Preloader(options);

  }

  models_Are_Loaded(timeout) {
    var start = Date.now();
    return new Promise(waitForResolution); // set the promise object within the models_Are_Loaded object
 
    // waitForResolution makes the decision whether the condition is met
    // or not met or the timeout has been exceeded which means
    // this promise will be rejected
    function waitForResolution(resolve, reject) {
        if (room.waitfor && room.waitfor.foo){
            resolve(room.waitfor.foo);
        }else if (timeout && (Date.now() - start) >= timeout){
          window.alert( "timeout on a slow internet...bonk")
          reject(new Error("timeout"));
        }else{
          setTimeout(waitForResolution.bind(this, resolve, reject), 30);
        }
    }
  }


  init_three(){

    this.scene = new THREE.Scene();
    this.cameraPersp = new THREE.PerspectiveCamera( 50,window.innerWidth/window.innerHeight,0.01,20000);
    this.camera = this.cameraPersp;
    this.cameras.push(this.camera)
    this.scene.add(this.cameras[0]);
    this.clock.start();
    this.mouseVector =  new THREE.Vector2( 0, 1 );
    this.raycaster = new THREE.Raycaster();
    this.renderer = new THREE.WebGLRenderer({
      alpha:!0,
      antialias:true,
      preserveDrawingBuffer: true,
    });

   
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.needsUpdate = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.toneMapping= THREE.ReinhardToneMapping;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setClearColor(0x000000); 

    this.renderer.toneMappingExposure= Math.pow(0.84, 5.0);
    
    this.renderer.physicallyCorrectLights= true;
   
    this.controls = new OrbitControls( 
      this.camera, 
      this.renderer.domElement
    );

    this.controls.maxDistance = 129;
    this.controls.maxPolarAngle= Math.PI/1.5;
    //this.controls.minAzimuthAngle = 0.1
  //  this.controls.minPolarAngle = 1.7;

   this.controls.target=(new THREE.Vector3(0,1.8,-1));

    this.cameras[0].position.z = 1;
    this.cameras[0].position.y = 2.0;
    document.body.appendChild(this.stater());    
    document.body.appendChild(this.scener());


    this.load_skydome()
    room.add_light();
   
   
    ///this.add_model();
    ///this.add_magic_plane(0,2,0, this.F, vshader_fire, fshader_fire);
  }
  ismesh(obj){
    return ( obj instanceof THREE.Mesh)?true:false;
  }
    /**
   * adds object to effect composer array for outlining
   * @param null
   */
  addSelectedObject ( object ) {
    this.selectedObjects = [];
    this.selectedObjects.push( object );
  }

  /**
   * method creates invisible proxy objects for raycasting efficiency
   * @param {object} mesh, three object for window, door etc.. 
   * @return null
   */
  add_proxy(meshname){

    let gb = new THREE.BoxHelper(room.scene.getObjectByName(meshname));
   
    gb.geometry.computeBoundingBox();
    let bx =  gb.geometry.boundingBox.clone(); 
   
    let middle = new THREE.Vector3();
    middle.x = (bx.max.x + bx.min.x) / 2.0;
    middle.y = (bx.max.y + bx.min.y) / 2.0;
    middle.z = (bx.max.z + bx.min.z) / 2.0;
    var pgeo = new THREE.BoxGeometry(bx.max.x-bx.min.x,  bx.max.y-bx.min.y,  bx.max.z-bx.min.z )
    var pmat = new THREE.MeshBasicMaterial({transparent: true, opacity:0.0, color:0xff0000} );
    var pcube = new THREE.Mesh(pgeo, pmat);
    pcube.name = meshname+"_PROXY";
    pcube.position.set( middle.x, middle.y, middle.z)
    this.proxys.add( pcube );  
  }


  add_plane(){
    const geometry = new THREE.PlaneGeometry( 184, 184 );

    const material = this.add_shader();




    const plane = new THREE.Mesh( geometry, material );
    plane.name="fu";
    this.scene.add( plane );
  }


  add_shaders(){
    if(room.scene.getObjectByName("Classic 1950's Emerson Wooden Cabinet Television") ){
      room.scene.getObjectByName("Classic 1950's Emerson Wooden Cabinet Television").children[4].material= this.tvNoise;
    }
    
   
   if(room.scene.getObjectByName("Logo") ){
    
      room.scene.getObjectByName("Logo").children.forEach(function(v){
        v.material = room.fire;
      });
     
    }
  }


  add_light(){

    this.scene.add(this.daylights)
   
   
    this.key_light = new THREE.PointLight( 0xffffff, 80, 23.5, 2  );
  
    this.key_light.position.set (0.0, 2.65, 0.0);
    this.key_light.rotation.set (Math.PI/2.0, 0.0, 0.0);
    this.key_light.name="key_light";
    //this.key_light.castShadow=true;
 //   this.key_light.lookAt (0.0, 0.0, 0.0);
    this.daylights.add(this.key_light);



    let hemi = new THREE.HemisphereLight( 0xffffbb, 0x080820, 3)
    this.daylights.add(hemi);


   // let luxlight = new THREE.DirectionalLight(0xffffff, 0.2);
    this.luxolight= new THREE.PointLight(0xffffff, 20, 3, 2);
  
    this.luxolight.position.set( 0.0, 0.1, -0.3);
    this.luxolight.name = "luxlight";
    //luxlight.target = room.scene.getObjectByName("Nike_Tekentafel");
    this.luxolight.castShadow=true;
    this.luxolight.shadow.mapSize= new THREE.Vector2(1024,1024);
//let a =new THREE.PointLightHelper( this.luxolight, 0.2)
//room.scene.add(a)
//let l =new THREE.PointLightHelper(luxlight, 0.1) 
//this.daylights.add(l)
    

    this.desklight = new THREE.PointLight(0xd3a937, 20, 2, 2);
    this.desklight.position.set( 0.0, -0.01, 0.0);
    this.desklight.castShadow=true;
    this.desklight.shadow.mapSize= new THREE.Vector2(1024,1024);
    this.desklight.name="desklight"
//let d =new THREE.PointLightHelper( this.desklight, 0.2)/
//room.scene.add(d)
    let wlight = new THREE.DirectionalLight(0xffffff);
    wlight.position.set( 0, 2, 10);
    wlight.name = "diploma_light";
    wlight.intensity=5.0;
    wlight.name="wallight";
 //  


    this.floorlight= new THREE.PointLight(0xd2997c, 20, 3, 2);
    this.floorlight.position.set(0.0,-0.05,0.0);
    this.floorlight.name = "floor_light";
//let f =new THREE.PointLightHelper( this.floorlight, 0.2)
//room.scene.add(f)
   
   // let p = room.scene.getObjectByName("proxy");
   // wlight.target=( p.getObjectByName("Diplomas_PROXY") )
  
    this.daylights.add(wlight)


    this.coucher = new THREE.PointLight(0xfffee0, 20, 3, 2);
    this.coucher.position.y=-0.02;
    this.coucher.position.x=0.1999;
    this.coucher.position.z=-0.1;
    
    this.coucher.castShadow=true;    
    this.coucher.name = "couchlight";


    this.sconce0= new THREE.PointLight(0xffcc99,2,2,2);
    this.sconce0.position.y=-0.3;
    this.sconce1= new THREE.PointLight(0xffcc99,3,4,2);
    this.sconce1.position.y=-0.3;
    this.sconce2= new THREE.PointLight(0xffcc99,5,1,2);
    this.sconce2.position.y=-0.3;


//let aa =new THREE.PointLightHelper( this.coucher, 0.2)
//room.scene.add(aa)    

//    this.daylights.add(luxlight)
   
    //this.daylights.add(dlight);
  //  this.daylights.add(clight);
  
    this.scene.fog = new THREE.FogExp2( 0xffffff,  .00015 );
    
    this.renderer.setClearColor(0x000000)

  }
  add_ground(){
    const geo = new THREE.PlaneGeometry(20,20);
    const mat = this.add_shader();
    const gr = new THREE.Mesh(geo, mat);
    gr.rotation.x=Math.PI/-2; 
    gr.position.set(0,-20,0);
    gr.receiveShadow = true;

    this.scene.add(gr);

  }

  getLogo() {
    return import(/* webpackChunkName: "Logo" */ '../src/components/Logo').then(Logo => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Logo.model.url[0]}`, 'Logo', Logo.model.pos[0], Logo.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Logo.model.url[1]}`, 'Linked', Logo.model.pos[1], Logo.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Logo.model.url[2]}`, 'Github', Logo.model.pos[2], Logo.model.rot );
      room.setMsg("logo");
    }).then(Module => {  
      ///doo more stuff
      
      room.models_Are_Loaded(40000).then(function(){

          room.scene.getObjectByName("Haywards Tripod Floor Lamp").add(room.floorlight) ;
          room.scene.getObjectByName("Vintage Industrial Bauhaus Desk Lamp by SIS, Germany 1950's.").add(room.desklight) ;
          room.scene.getObjectByName("Classic Luxo Lamp, first manufactured in 1937").add(room.luxolight) ;
         
          room.scene.getObjectByName("Sconces0").add(room.sconce0) ;
          room.scene.getObjectByName("Oscar Torlasco wall sconce 1960").add(room.sconce1) ;
          room.scene.getObjectByName("Sconces2").add(room.sconce2) ;
                   


          room.daylights.getObjectByName("wallight").target = room.scene.getObjectByName("Diplomas"); 
          room.fanblades = room.scene.getObjectByName("Ceiling Fan").getObjectByName("tex-fine_woods");
          room.fanOn = true;
          room.add_shaders();


          setTimeout(()=>{

            room.loaded.forEach(function(v){
              room.add_proxy(v);
            });
          
            let sat = room.scene.getObjectByName("'Saturn' Floor Lamp by the Laurel Lamp Company, 1950's")
            sat.add(room.coucher) ;
            let bulb = sat.getObjectByName("lights"); 
            bulb.material.emissive.setHex(0xffffff);                      
            bulb.material.emissiveIntensity= 20;                      

            room.scene.getObjectByName("proxy").getObjectByName(sat.name+"_PROXY").position.y-=0.5;
            room.addCarpet();


          }, 2000);
          
         

        });

     });
  }


  addCarpet(){
    this.setMsg("adding carpet "+this.scene.children.length);
    this.setMsg("");
    let geo = new THREE.CircleGeometry( 3, 32 );
    let geo1 = new THREE.RingGeometry( 2.99, 3.01, 32 );
    

    let mesh = new THREE.Mesh(geo, this.carp);
    let mesh1 = new THREE.Mesh(geo1, this.bark);

    mesh.receiveShadow=true;
    mesh.name="carpet";
    mesh.position.x=-4.0;
    mesh.position.y=0.01;
    mesh1.position.z=0.01;
    mesh1.receiveShadow=true;
    mesh1.name="carpetedge";
    mesh.rotation.x=Math.PI/-2;
    mesh.add(mesh1)
    room.scene.add(mesh)
  }





  getBaseboard() {
    return import(/* webpackChunkName: "Baseboard" */ '../src/components/Baseboard')
    .then(Baseboard => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Baseboard.model.url}`, 'Baseboard', Baseboard.model.pos, Baseboard.model.rot );
    }).then(Module => {  
      room.getTrim();
    });
  }

  getTrim() {
    return import(/* webpackChunkName: "Trim" */ '../src/components/Upper_trim')
    .then(Upper_trim => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Upper_trim.model.url}`, 'Upper_trim', Upper_trim.model.pos, Upper_trim.model.rot );
    }).then(Module => {  
      room.getDoors();
    });
  }

  getDoors() {
    return import(/* webpackChunkName: "DoorsWindows" */ '../src/components/Doors_windows')
    .then(Doors_windows => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Doors_windows.model.url}`, 'Doors_windows', Doors_windows.model.pos, Doors_windows.model.rot );
    }).then(Module => {  
      room.getTv();
     });
  }

  getTv(){
    return import(/* webpackChunkName: "TV" */ '../src/components/Television')
    .then(Television => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Television.model.url}`, Television.model.nom, Television.model.pos, Television.model.rot );
      room.loaded.push(Television.model.nom);
    }).then(Module => { 
      room.getFloorLamp();
    });
  }
 getFloorLamp(){
    return import(/* webpackChunkName: "Lamp" */ '../src/components/Floor_lamp')
    .then(Floor_lamp => {
      room.mloader
      .load_GLTF( `${room.modelsPath}/${Floor_lamp.model.url}`, Floor_lamp.model.nom, Floor_lamp.model.pos, Floor_lamp.model.rot );
      room.loaded.push(Floor_lamp.model.nom);
    }).then(Module => { 
      room.getEames();
    });
  }

  getEames() {
    return import(/* webpackChunkName: "Eames" */ '../src/components/Eames')
    .then(Eames => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Eames.model.url}`, Eames.model.nom, Eames.model.pos, Eames.model.rot );
      room.loaded.push(Eames.model.nom);
    }).then(Module => { 
      room.getKardiel();
    });
  }

  getKardiel() {
    return import(/* webpackChunkName: "Kardiel" */ '../src/components/Kardiel')
    .then(Kardiel => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Kardiel.model.url}`, Kardiel.model.nom, Kardiel.model.pos, Kardiel.model.rot );
      room.loaded.push(Kardiel.model.nom);
    }).then(Module => { 
       room.getNike();
    });
  }

  getNike() {
    return import(/* webpackChunkName: "Nike" */ '../src/components/Nike')
    .then(Nike => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Nike.model.url}`, Nike.model.nom, Nike.model.pos, Nike.model.rot );
      room.loaded.push(Nike.model.nom);
    }).then(Module => { 
      room.getProtractor();
    });
  }

  getProtractor() {
    return import(/* webpackChunkName: "Protractor" */ '../src/components/Protractor')
    .then(Protractor => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Protractor.model.url}`, Protractor.model.nom, Protractor.model.pos, Protractor.model.rot );
      room.loaded.push(Protractor.model.nom);
    }).then(Module => { 
      room.getDesklamp();
    });
  }
  getDesklamp() {
    return import(/* webpackChunkName: "Desklamp" */ '../src/components/Desklamp')
    .then(Desklamp => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Desklamp.model.url}`, Desklamp.model.nom, Desklamp.model.pos, Desklamp.model.rot );
      room.loaded.push(Desklamp.model.nom);
    }).then(Module => { 
      room.getLuxo();
    });
  }

  getLuxo() {
    return import(/* webpackChunkName: "Luxo" */ '../src/components/Luxo')
    .then(Luxo => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Luxo.model.url}`, Luxo.model.nom, Luxo.model.pos, Luxo.model.rot );
      room.loaded.push(Luxo.model.nom);
    }).then(Module => { 
      room.getDraftchair();
    });
  }

  getDraftchair() {
    return import(/* webpackChunkName: "Draftchair" */ '../src/components/Draftchair')
    .then(Draftchair => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Draftchair.model.url}`, Draftchair.model.nom, Draftchair.model.pos, Draftchair.model.rot );
      room.loaded.push(Draftchair.model.nom);
    }).then(Module => { 
      room.getHitier();
    });
  }
  getHitier(){
    return import(/* webpackChunkName: "Hitier" */ '../src/components/Hitier')
    .then(Hitier => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Hitier.model.url}`, Hitier.model.nom, Hitier.model.pos, Hitier.model.rot );
      room.loaded.push(Hitier.model.nom);
    }).then(Module => { 
      room.getSideboard();
    });
  }

  getSideboard(){
    return import(/* webpackChunkName: "Sideboard" */ '../src/components/Sideboard')
    .then(Sideboard => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Sideboard.model.url}`, Sideboard.model.nom, Sideboard.model.pos, Sideboard.model.rot );
      room.loaded.push(Sideboard.model.nom); 
    }).then(Module => { 
      room.getBertoia();
    });
  }

  getBertoia(){
    return import(/* webpackChunkName: "Bertoia" */ '../src/components/Bertoia')
    .then(Bertoia => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Bertoia.model.url}`, Bertoia.model.nom, Bertoia.model.pos, Bertoia.model.rot );
      room.loaded.push(Bertoia.model.nom); 
    }).then(Module => { 
      room.getLaurel();
    });
  }

  getLaurel(){
    return import(/* webpackChunkName: "Laurel" */ '../src/components/Laurel').then(Laurel => {
        room.mloader.load_GLTF( `${room.modelsPath}/${Laurel.model.url}`, Laurel.model.nom, Laurel.model.pos, Laurel.model.rot );
        room.loaded.push(Laurel.model.nom); 
    }).then(Module => { 
        room.getFan();
    });
  }
  
  getFan(){
    return import(/* webpackChunkName: "Fan" */ '../src/components/Fan')
    .then(Fan => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Fan.model.url}`, Fan.model.nom, Fan.model.pos, Fan.model.rot );
    }).then(Module => { 
      room.getDiplomas();
    });
  }
  
  getDiplomas(){
    return import(/* webpackChunkName: "Diplomas" */ '../src/components/Diplomas')
    .then(Diplomas => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}0_clean.glb`, 'Diplomas', Diplomas.model.pos[0], Diplomas.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}1_clean.glb`, 'Diplomas', Diplomas.model.pos[1], Diplomas.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}2_clean.glb`, 'Diplomas', Diplomas.model.pos[2], Diplomas.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}3_clean.glb`, 'Diplomas', Diplomas.model.pos[3], Diplomas.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}4_clean.glb`, 'Diplomas', Diplomas.model.pos[4], Diplomas.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Diplomas.model.url}5_clean.glb`, 'Diplomas', Diplomas.model.pos[5], Diplomas.model.rot );
    }).then(Module => { 
      room.getSconces();
    });
  }

  getSconces(){
    return import(/* webpackChunkName: "Sconces" */ '../src/components/Sconces')
    .then(Sconces => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Sconces.model.url}`, 'Sconces0', Sconces.model.pos[0], Sconces.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Sconces.model.url}`,  Sconces.model.nom, Sconces.model.pos[1], Sconces.model.rot );
      room.mloader.load_GLTF( `${room.modelsPath}/${Sconces.model.url}`, 'Sconces2', Sconces.model.pos[2], Sconces.model.rot );
      room.loaded.push( Sconces.model.nom); 
    }).then(Module => { 
      room.getRadiators();
    });
  }
  
  getRadiators(){
    return import(/* webpackChunkName: "Radiator" */ '../src/components/Radiator')
    .then(Radiator => {
      room.mloader.load_GLTF( `${room.modelsPath}/${Radiator.model.url}`, Radiator.model.nom+"0", Radiator.model.pos[0], Radiator.model.rot[0] );
      room.mloader.load_GLTF( `${room.modelsPath}/${Radiator.model.url}`, Radiator.model.nom, Radiator.model.pos[1], Radiator.model.rot[0] );
      room.mloader.load_GLTF( `${room.modelsPath}/${Radiator.model.url}`, Radiator.model.nom+"1", Radiator.model.pos[2], Radiator.model.rot[1] );
      room.mloader.load_GLTF( `${room.modelsPath}/${Radiator.model.url}`, Radiator.model.nom+"2", Radiator.model.pos[3], Radiator.model.rot[1] );
    }).then(Module => { 
      room.getLogo();
    });
  }

  /**
   * Creates sphere environment dome and maps bg image to geometry material
   * @return null
   */
  load_skydome(){

    this.cubeLoader = new THREE.CubeTextureLoader();
    this.textureLoader = new THREE.TextureLoader();
    this.cubeLoader.setPath( `${this.cubesPath}/${this.SkyhookPath}/` );

    this.skymap  = this.textureLoader.load(this.cubesPath+'/'+this.SkyhookPath+'/skyball.png');
    this.skymap.wrapS = THREE.RepeatWrapping;
    this.skymap.wrapT = THREE.RepeatWrapping;
    this.skymap.repeat.set( 1, 1 );
    this.skymap.offset.set( 0, 0 );

    this.enviroMap = this.cubeLoader.load( [
      'px.png', 'nx.png',
      'py.png', 'ny.png',
      'pz.png', 'nz.png'
    ] );
    this.enviroMap.name = "reflection"

    this.scene.background =  this.enviroMap;
    let skyGeo = new THREE.SphereGeometry( 400, 64, 30 );
    var skyMat = new THREE.MeshStandardMaterial( { 
      map: this.skymap, 
      side: THREE.DoubleSide,
      name:"skymap"
    } );
  
    this.skyr = new THREE.Mesh( skyGeo, skyMat );
    this.skyr.name ="skydome";
    this.scene.add( this.skyr );
    this.skyr.position.y+=18;
    this.skyr.rotation.y=Math.PI/2;
  }

   /**
   * Appends the render canvas to a DOM element
   * @return {object} DOM element with THREE canvas
   */
  scener(){
    const element = document.createElement('div');
    element.id ="myscene"; 
    element.appendChild(this.renderer.domElement); 
    return element; 
  }

  /**
   * Appends the render canvas to a DOM element
   * @return {object} DOM element with THREE canvas
   */
  stater(){
    const element = document.createElement('div');
    element.id ="mystate"; 
    element.appendChild(this.renderer.domElement); 
    return element; 
  }
  setMsg(m){
    document.getElementById("mystate").innerHTML = m; 
  }


  /**
  * Adds effect composer to renderer
  */
  add_efx(){
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    this.composer.stencilBuffer = true;
    this.composer.depthBuffer = true;
    this.outs = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight),this.scene, this.camera, this.scene.children);
    this.outs.renderToScreen = true;
    this.outs.enabled= true;
    this.outs.edgeThickness= 1.2;
    this.outs.visibleEdgeColor = new THREE.Color( 0x343e83 );
    this.outs.hiddenEdgeColor =  new THREE.Color( 0xffffff );

    this.composer.addPass(this.outs);
    this.composer.setSize(window.innerWidth, window.innerHeight);
  }

  spin_object(){

    if( this.dragObject){
      this.fanOn = false;
      this.controls.target.lerp(this.posepos, 0.1);
      this.dragObject.position.lerp( this.posepos, 0.1)
      this.cameras[0].position.lerp(new THREE.Vector3(0,1.8,0), 0.1)
      this.dragObject.rotation.y+=0.04;

      this.key_light.position.x=this.controls.target.x
      this.key_light.position.z=this.controls.target.z
    }
  }




  animate() {
  
    
    this.deltaTime = this.clock.getDelta();
    ///this.mixers.forEach(mixer=>mixer.update(this.deltaTime));
    this.spin_object();
    this.U.u_time.value = this.clock.getElapsedTime();
    this.U.u_dtime.value = this.deltaTime;
    this.W.u_time.value += this.deltaTime;
    this.F.u_time.value += this.deltaTime;
    if( this.fanOn)this.fanblades.rotation.y+=0.1;
  //  this.U.u_randy.value = Math.random();
    if( this.composer){
      this.composer.render();
    }else{
      this.renderer.render();
    }
    this.controls.update();
    this.rframe++;
   
    if((!room.waitfor.foo)&&( room.scene.children.length ==38)){
      room.waitfor.foo="bar";
    }
    requestAnimationFrame( function(){ room.animate(); } );
  }

}