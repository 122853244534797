import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import * as THREE from 'three';
import {TexMap} from './TexMapper';


const drywall = new TexMap({
              texPath:"../src/texture", dir:"wall", xrep:12, yrep:6, type:"standard",
              xoff:0, yoff:0, aoi:2, roughi:0, metali:0, roti: 0.0, hook:"s"
            }).output();

const ceiling = new TexMap({
              texPath:"../src/texture", dir:"ceiling", xrep:24, yrep:24, type:"standard",
              xoff:0, yoff:0, aoi:2, roughi:0, metali:0, roti: 0, hook:"s"
            }).output();

const floor = new TexMap({
              texPath:"../src/texture", dir:"floor/parquet0", xrep:32, yrep:32, type:"standard",
              xoff:0, yoff:0, aoi:2, roughi:0.8, metali:0, roti: 0, hook:"s"
            }).output();

const trim = new TexMap({
              texPath:"../src/texture", dir:"wood", xrep:2, yrep:2, type:"standard",
              xoff:0, yoff:0,  aoi:0.1,  roughi:0.62, metali:0.13, roti: Math.PI/2.0, hook:"s"
            }).output();

const trimX = new THREE.MeshStandardMaterial({color: 0xb6d3e1});


const v_wood = new TexMap({
              texPath:"../src/texture", dir:"fine_wood", xrep:8, yrep:8, type:"standard",side: THREE.DoubleSide,
              xoff:0, yoff:0,  aoi:0.1,  roughi:0.62, metali:0.13, roti: Math.PI/4.0, hook:"s"
            }).output();

const h_wood = new TexMap({
              texPath:"../src/texture", dir:"fine_wood", xrep:4, yrep:4, type:"standard",
              xoff:0, yoff:0,  aoi:0.1,  roughi:0.62, metali:0.13, roti: Math.PI/2.0, hook:"s"
            }).output();

const steel = new TexMap({
              texPath:"../src/texture", dir:"steel", xrep:1, yrep:1, type:"phong", color:0xffffff,
              xoff:0, yoff:0,  aoi:0.1,   roti: 0, hook:"s",opac:1.0,
            }).output();

const chrome = new TexMap({
              texPath:"../src/texture",  xrep:1, yrep:1, type:"phong", color:0xffcc99,
              xoff:0, yoff:0,  aoi:0.1,   roti: 0, hook:"s", opac:1.0,
            }).output();


const lighted = new TexMap({
              texPath:"../src/texture", name:"lights", xrep:1, yrep:1, type:"phong", color:0xffffff,
              xoff:0, yoff:0,  aoi:0.1,   roti: 0, hook:"s", opac:1.0, emmissive: 0xffffff, emmissiveIntensity:20.0,
            }).output();


const glass = new TexMap({
              texPath:"../src/texture", xrep:1, yrep:1, type:"phong", color:0xffffff,
              xoff:0, yoff:0,  aoi:0.1,   roti: 0, hook:"s", opac:0.2,
            }).output();

const fabric = new TexMap({
              texPath:"../src/texture",dir:"fabric/brun", xrep:1, yrep:1, 
              type:"standard", color:0xd9c7b4, emmissive:0xd9c7b4, emmissiveIntensity:1.0,
              xoff:0, yoff:0,  aoi:0.1,   roti: 1.0, hook:"s", 
            }).output();
const spad= new TexMap({
              type:"phong", color:0x000000,
               aoi:0.1,   roti: 0, hook:"s", opac:1.0,
            }).output();

const spad1= new TexMap({
              texPath:"../src/texture",dir:"fabric/silverpad", xrep:2, yrep:1, 
              type:"standard", xoff:0, yoff:0,  aoi:0.1,   roti: 1.0, hook:"s", 
            }).output();

class ParseModel{
  constructor(options, NOM, pos, rot){
    this.model = new THREE.Group();
    this.model.name = NOM;

    if( pos){
     
      this.model.position.x= pos[0];
      this.model.position.y= pos[1];
      this.model.position.z= pos[2];
    }
    if( rot){
     
      this.model.rotateX( rot[0]);
      this.model.rotateY( rot[1]);
      this.model.rotateZ( rot[2]);
      ///console.log(NOM+" "+this.model.rotation.y )
    }
    this.shad = options.tvNoise;
    this.scene= options.scene;
   
  //  this.scene.name = NOM;
  }

  load_scene(scene){
    this.load_kids(scene.children);
    this.scene.add( this.model ); 
  }

  load_kids(grp){
  

   

    for(var i = 0; i < grp.length; i++){
      if( grp[i] instanceof THREE.Mesh){
        grp[i].receiveShadow = true;
        grp[i].castShadow = true;
        var m = new THREE.MeshBasicMaterial();
    
        if (grp[i].name == "floor"){
          grp[i].material = floor;
        }else if( grp[i].name.indexOf("wall")>=0 ){
          grp[i].material = drywall;
        }else if( grp[i].name.indexOf("drywall")>=0 ){
          
        }else if(( grp[i].name.indexOf("baseboard")>=0 )||( grp[i].name.indexOf("trim")>=0 ) ){
          grp[i].material = trim;

       // }else if( grp[i].name.indexOf("Cube")>=0 ){
     
        }else{

     
          switch(grp[i].material.name){
            case "black leather.002":
            case "black leather.001":
             case "black leather.003":
              grp[i].material = spad;
              break;
            case "1cushion":
             
              grp[i].material = spad1;
              break;

            case "drywall": 
              grp[i].material = drywall;
              break;
            case "blue matte": 
              grp[i].material= ceiling;
              break; 
            case "h_wood":
              grp[i].material = v_wood;    
              break;
            case "v_wood":
            case "fine_wood_v":
            case "fine_wood_v,001":
            case "fine_wood":
            case "fine_wood.001":
              grp[i].material = h_wood;    
              break;
            case "steel":
              grp[i].material = steel;    
              break;
            case "metal":
            case "chrome.002":
            case "chrome.001":
            case "chrome.003":
            case "Chrome":
            
                grp[i].material = chrome;    
              break;
            case "glass":
              grp[i].material =  glass ;    
              break;
            case "fabric":
              grp[i].material =  fabric ;    
              break;
            case "light bulb":
            case "light.001":



              
              grp[i].material= lighted ;
 
              
              break;  

          }
        }
        grp[i].name = grp[i].material.name; 
        this.model.add(grp[i].clone() )
      }
      if( grp[i] instanceof THREE.Group){
   
        this.load_kids(grp[i].children)
      }
    }
  } 
}


//parsemodel components and loads to room scene
class ModelLoad{
  constructor(options){
    this.room = options.room;
    this.gloader = new GLTFLoader();

    var dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath( '../src/draco/' )
    this.gloader.setDRACOLoader( dracoLoader );
    
  }  
  load_GLTF(URL, NOM, pos, rot ){
//console.log(NOM)

    let mp = new ParseModel(this.room, NOM, pos, rot); 
   
    this.gloader.load(
      URL,
      function ( gltf ) {
    //console.log("RAW GLTF")
    //console.log(gltf.scene);


        //load and parse gltf model scene
        gltf.scene.name = NOM;
       
        mp.load_scene( gltf.scene );
      },
      function(){
       // console.log("...")
      }
    );
 }


  
}
export {ModelLoad};
