import * as THREE from 'three';




class TexMap{

	constructor(options){
		this.diffuse= null;
		
		this.rough= null;
		this.ao= null;
		this.norm= null;
		
		if( options.dir){
			this.imgLoader = new THREE.TextureLoader();
			this.diffuse = this.imgLoader.load(`${options.texPath}/${options.dir}/diffuse.png`);
			this.norm = this.imgLoader.load(`${options.texPath}/${options.dir}/norm.png`);
			this.ao = this.imgLoader.load(`${options.texPath}/${options.dir}/ao.png`);
			if(options.roti){
				this.diffuse.rotation = options.roti;
				this.norm.rotation = options.roti;
				this.ao.rotation = options.roti;
			}

			this.diffuse.wrapS = THREE.RepeatWrapping;
			this.diffuse.wrapT = THREE.RepeatWrapping;
			this.diffuse.repeat.set( options.xrep, options.yrep );
			this.diffuse.offset.set( options.xoff, options.yoff );

			this.norm.wrapS = THREE.RepeatWrapping;
			this.norm.wrapT = THREE.RepeatWrapping;
			this.norm.repeat.set( options.xrep, options.yrep );
			this.norm.offset.set( options.xoff, options.yoff );

			this.ao.wrapS = THREE.RepeatWrapping;
			this.ao.wrapT = THREE.RepeatWrapping;
			this.ao.repeat.set( options.xrep, options.yrep );
			this.ao.offset.set( options.xoff, options.yoff );

			if( options.roughi){
				this.rough = this.imgLoader.load(`${options.texPath}/${options.dir}/rough.png`);
				this.rough.rotation = options.roti;

				this.rough.wrapS = THREE.RepeatWrapping;
				this.rough.wrapT = THREE.RepeatWrapping;
				this.rough.repeat.set( options.xrep, options.yrep );
				this.rough.offset.set( options.xoff, options.yoff );
			}
		}
		const cubesPath = '../src/texture/cube';
		
		const SkyhookPath ='Canary';

		let cubeLoader = new THREE.CubeTextureLoader();
			
		
		cubeLoader.setPath( `${cubesPath}/${SkyhookPath}/` );

		let envMap0= cubeLoader.load( [
			'px.png', 'nx.png',
			'py.png', 'ny.png',
			'pz.png', 'nz.png'
		] );



		
		let h = (options.hook)?options.hook:"hook";

		switch (options.type){
			case "standard":
				this.mat =	new THREE.MeshStandardMaterial( {
				 	map: this.diffuse,	
					aoMap: this.ao,
					aoMapIntensity:(options.aoi)?options.aoi:0.5,
					side: (options.side)?options.side:THREE.FrontSide,
					roughnessMap:this.rough,
					roughness:(options.roughi)?options.roughi:0.25,
					metalness:(options.metali)?options.metali:0,
					color:(options.color)?options.color:0xffffff,
					normalMap: this.norm,
					envMap:envMap0,
					name: "tex-"+options.dir+h,
					transparent: (options.opac)?true:false,
					opacity: (options.opac)?options.opac:1.0,
					emissive: (options.emissive)?options.emissive:0x000000,
					emissiveIntensity: (options.emissive)?options.emissiveIntensity:1.0,
					
				});
				break;
			case "phong":
			
				this.mat =	new THREE.MeshPhongMaterial( {
				 	color: options.color,
				 	shininess: 1.0,
				 	map: this.diffuse,	
					name:(options.name)?options.name:"mr no name",
					aoMap: this.ao,
					aoMapIntensity:(options.aoi)?options.aoi:0.5,
					side: THREE.FrontSide,
					reflectivity: 1.0,
					emissive:(options.emissive)?options.emissive:0x000000,
					emissiveIntensity:(options.emissiveIntensity)?options.emissiveIntensity:0,

					normalMap: this.norm,
					envMap:envMap0,
					
					specular: 0x0000ff,
					transparent: true,
					opacity: options.opac,
					blending: THREE.CustomBlending,
					blendEquation : THREE.AddEquation,
					blendSrc: THREE.SrcAlphaFactor,
					blendDst: THREE.OneMinusSrcAlphaFactor,
					depthWrite: true


				});
			
				break;
				case "lambert":
				this.mat =	new THREE.MeshLambertMaterial( {
				 	color: options.color,
				 	side: THREE.FrontSide,
					name: "tex-"+options.dir+h,
					blending: THREE.CustomBlending,
					blendEquation : THREE.AddEquation,
					blendSrc: THREE.SrcAlphaFactor,
					blendDst: THREE.OneMinusSrcAlphaFactor,
					depthWrite: true

				});
				break;
		}
		

	}

	output(){
		return this.mat;
	}
}




export{TexMap}